import 'core-js/stable';
import Vue from 'vue';
import App from './App';
import router from './router';
import CoreuiVue from '@coreui/vue';
import {iconsSet as icons} from './assets/icons/icons.js';
import store from './store';
import moment from 'moment';
import Vuelidate from 'vuelidate';

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.use(Vuelidate);
Vue.prototype.$log = console.log.bind(console);

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('ll');
  }
});

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
});
