import Vue from 'vue';
import Router from 'vue-router';

// Containers
const TheContainer = () => import('@/containers/TheContainer');

// Views
const Dashboard = () => import('@/views/Dashboard');
const CourseType = () => import( '@/views/CourseType');
const Signup = () => import( '@/views/Signup');
const LicenseInfo = () => import( '@/views/Info/LicenseInfo');

Vue.use(Router);

export default new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({y: 0}),
  routes: configRoutes()
});

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            label: 'Startseite'
          }
        },
        {
          path: '/course/:type',
          name: 'course',
          component: CourseType,
          props: true,
          meta: {
            label: 'Kurse anzeigen'
          }
        },
        {
          path: '/course/:type/:number',
          name: 'motocourse',
          component: CourseType,
          props: true,
          meta: {
            label: 'Motorradkurse anzeigen'
          }
        },
        {
          path: '/signup/:courseId',
          name: 'signup',
          component: Signup,
          props: true,
          meta: {
            label: 'Kursanmeldung'
          }
        },
        {
          path: '/info/license',
          name: 'license-info',
          component: LicenseInfo,
          meta: {
            label: 'Lernfahrausweis'
          }
        },
      ]
    }
  ];
}

